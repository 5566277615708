
import axios from "axios";
import { showDialog } from 'vant';
import { encryptDes } from "./des";
export const Service = axios.create({
    timeout: 60000, //延迟时间
})
import router from '../router/index'

function sort_ascii(obj: any) {
    let arr = new Array();
    let num = 0;
    for (let i in obj) {
        arr[num] = i;
        num++;
    }
    let sortArr = arr.sort();
    let str = ""; //自定义排序字符串
    for (let i in sortArr) {
        if (undefined != obj[sortArr[i]] && "" != obj[sortArr[i]]) {
            str += sortArr[i] + "=" + obj[sortArr[i]] + "&";
        }
    }
    //去除两侧字符串
    let char = "&";
    str = str.replace(new RegExp("^\\" + char + "+|\\" + char + "+$", "g"), "");
    return str;
}

// 请求拦截
Service.interceptors.request.use(config => {
    let token = localStorage.getItem('jwt');
    let tokenHeader = localStorage.getItem('tokenHeader');

    if (token && tokenHeader) {
        config.headers[tokenHeader] = String(token);
    }
    config.params = {
        ...config.params,
        timestamp: +new Date(),
    };
    let ed = encryptDes(sort_ascii(config.params), "2sSofJ58RsQ=");
    config.params.signature = ed;

    return config
})
// 响应拦截
Service.interceptors.response.use(response => {
    if (response.data.code == 200 || response.data.code == 0) {
        
        return response.data
    } else if (response.data.code == 401) {
        // localStorage.removeItem("jwt")
        // localStorage.removeItem("mobile")
        // return router.replace({ path: '/index' })
    } else if (response.data.code == 403) {
        if(response.data.message == '已通知'){
            return
        }
        showDialog({
            title: '提示',
            message: response.data.message
        })
    } else if (response.data.code == 500) {
        showDialog({
            title: '提示',
            message: response.data.message
        })
    } else if (response.data.code == 404) {
        showDialog({
            title: '提示',
            message: response.data.message
        })
        // return router.replace({ path: '/404' })
    }else {
        console.log('失败')
        return Promise.reject(response);
    }
}, err => {
    // showDialog({
    //     title: '提示',
    //     message: '请求超时'
    // })
    return err
})