
// localStorage 的存储
export function setStorage(key: any, value: any) {
  if (typeof value == 'object') {
    window.localStorage.setItem(key, JSON.stringify(value));
  }else{
    window.localStorage.setItem(key, value);
  }
  
}

// localStorage 的读取
export function getStorage(key: any) {
  // obj=>sting=>obj
  const value = window.localStorage.getItem(key);
  try {
    return JSON.parse(window.localStorage.getItem(key) || 'null');
  } catch (error) {
    return value;
  }
}

// localStorage 的删除
export function removeStorage(key: any) {
  window.localStorage.removeItem(key);
}

// 删除所有的 localStorage
export function clearStorage() {
  window.localStorage.clear();
}



// sessionStorage 的存储
export function setSessionStorage(key: any, value: any) {
  if (typeof value == 'object') {
    window.sessionStorage.setItem(key, JSON.stringify(value));
  }else{
    window.sessionStorage.setItem(key, value);
  }
}

// sessionStorage 的读取
export function getSessionStorage(key: any) {
  // obj=>sting=>obj
  const value = window.sessionStorage.getItem(key);
  try {
    return JSON.parse(window.sessionStorage.getItem(key) || 'null');
  } catch (error) {
    return value;
  }
}

// sessionStorage 的删除
export function removeSessionStorage(key: any) {
  window.sessionStorage.removeItem(key);
}

// 删除所有的 sessionStorage
export function clearSessionStorage() {
  window.sessionStorage.clear();
}

