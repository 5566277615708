import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'lib-flexible'
import moment from 'moment'
import Api from '@/api'
import { 
  setStorage, 
  getStorage, 
  removeStorage, 
  clearStorage,
  setSessionStorage,
  getSessionStorage,
  removeSessionStorage,
  clearSessionStorage
 } from '@/utils/local'
import '@/assets/css/com.scss';
import '@/assets/font/font.css';


import 'vant/lib/index.css';
import vant from 'vant';
// import {Toast} from 'vant';
const app = createApp(App)

if (process.env.NODE_ENV !== "development") {
  require("./utils/aplus.js");
}

  require("./utils/vconsole.ts");
  if (process.env.NODE_ENV !== "production") {
  require("./utils/vconsole.ts");
}
// require("./utils/vconsole.ts");

app.config.globalProperties.$moment = moment
app.config.globalProperties.$api = Api
app.config.globalProperties.$setStorage = setStorage
app.config.globalProperties.$getStorage = getStorage
app.config.globalProperties.$removeStorage = removeStorage
app.config.globalProperties.$clearStorage = clearStorage
app.config.globalProperties.$setSessionStorage = setSessionStorage
app.config.globalProperties.$getSessionStorage = getSessionStorage
app.config.globalProperties.$removeSessionStorage = removeSessionStorage
app.config.globalProperties.$clearSessionStorage = clearSessionStorage
// app.config.globalProperties.$toast = Toast

app.use(vant)
app.use(store)
app.use(router)
app.mount('#app')